import React from "react";
import { graphql } from "gatsby";
import Header from "../components/Header";
import LeftSideBar from "../components/LeftSideBar";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useI18next, Trans } from "gatsby-plugin-react-i18next";
import Moment from "react-moment";
import theme from "../config/theme";
import "../styles/layout.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CategoryList from "../components/CategoryList";

import { Container, Box, Card, CardContent, Breadcrumbs, Typography, Button } from "@mui/material";

const CategoryTemplate = props => {
    const { language, languages, originalPath } = useI18next();
    const { data } = props;

    console.log(props);
    return (
        <ThemeProvider theme={theme}>
            <Container disableGutters maxWidth={false}>
                <Header props={props} showMenu={true} />
                <Box display={"flex"}>
                    <Box sx={{ display: { xs: "none", md: "flex" } }}>
                        <LeftSideBar subcategories={props.data.subcategories ? props.data.subcategories.edges : []} slug={props.pageContext.slug} variant={"permanent"} isOpen={true} />
                    </Box>
                    {data.category && (
                        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                            <CategoryList categories={data.category ? [data.category] : []}></CategoryList>
                        </Box>
                    )}
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default CategoryTemplate;

export const query = graphql`
    query ($slug: String!, $language: String!, $locale: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        category: strapiCategory(slug: { eq: $slug }, locale: { eq: $locale }) {
            id
            name
            locale
            slug
            order
            updated_at
            subcategories {
                id
                name
                slug
                order
                locale
            }
            image {
                localFile {
                    publicURL
                }
            }
        }
        subcategories: allStrapiSubcategory(filter: { locale: { eq: $locale } }) {
            edges {
                node {
                    id
                    name
                    slug
                    order
                    locale
                    articles {
                        id
                        title
                        slug
                        order
                        locale
                    }
                    category {
                        id
                        name
                        slug
                        order
                        locale
                    }
                }
            }
        }
    }
`;
