import * as React from "react";
import { navigate } from "gatsby";
import { styled } from "@mui/material/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { Container, Box, Grid, Paper, Typography, Divider, Button, Chip, Breadcrumbs } from "@mui/material";

const CategoryList = ({ categories, showBreadcrumb }) => {
    const { language, languages, defaultLanguage, originalPath, path, siteUrl } = useI18next();
    const { t } = useTranslation();

    let sortedCategories = categories
        .map(category => {
            return {
                ...category,
                subcategories: category.subcategories.sort(function (a, b) {
                    return b.order - a.order;
                }),
            };
        })
        .sort(function (a, b) {
            return b.order - a.order;
        });

    let urlLanguageCode = "";

    if (language != defaultLanguage) {
        urlLanguageCode = `${language}/`;
    }

    const openCategory = slug => event => {
        window.open(`${siteUrl}/${urlLanguageCode}category/${slug}`, "_self");
        // navigate(`${siteUrl}${language}/category/${slug}`, { replace: true })
    };

    const openSubcategory = slug => event => {
        window.open(`${siteUrl}/${urlLanguageCode}subcategory/${slug}`, "_self");
        // navigate(`${siteUrl}${language}/subcategory/${slug}`, { replace: true })
    };

    return (
        <Container maxWidth={false}>
            <Box display={"flex"} alignContent={"center"} flexDirection={"column"} gap={8}>
                {sortedCategories.map(category => (
                    <Grid
                        style={{
                            boxShadow: "0px 12px 16px rgba(0, 0, 0, 0.04), 0px 4px 56px rgba(0, 0, 0, 0.04)",
                            borderRadius: 8,
                            minHeight: 200,
                        }}
                        key={category.id}
                        container
                    >
                        {showBreadcrumb && (
                            <Grid xs={12} sm={4} p={2} flexDirection={"column"} item display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                <Box component={"img"} sx={{ maxHeight: 98 }} src={category.image ? category.image.localFile.publicURL : null}></Box>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Box component={"span"} sx={{ cursor: "pointer" }} onClick={openCategory(category.slug)}>
                                        <Typography style={{ fontSize: 24 }} color="text.primary">
                                            {category.name}
                                        </Typography>
                                    </Box>
                                </Breadcrumbs>
                                <Chip color="primary" label={`${category.count ? category.count : category.subcategories.length} ${t("sections")}`} />
                            </Grid>
                        )}
                        {showBreadcrumb && <Divider sx={{ display: { xs: "none", sm: "flex" } }} orientation="vertical" light variant="middle" flexItem style={{ marginRight: "-1px" }} />}
                        <Grid item xs={12} sm={showBreadcrumb ? 8 : 12} p={2}>
                            <Box>
                                {category.subcategories.map(subcategory => (
                                    <Box
                                        key={subcategory.id}
                                        display={"flex"}
                                        p={1}
                                        sx={{ cursor: "pointer" }}
                                        justifyContent={"space-between"}
                                        component={"span"}
                                        fullWidth
                                        onClick={openSubcategory(subcategory.slug)}
                                    >
                                        <Typography style={{ fontSize: 16 }}>{subcategory.name}</Typography>
                                        <ArrowForwardIosIcon />
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                ))}
            </Box>
        </Container>
    );
};

export default CategoryList;
